/* stylelint-disable */
// Sizing Helper
$axes: (
  width: w,
  height: h
);

// Build Spacing Helper Classes

@each $_breakpoint, $_breakpoint-val in $__breakpoints {
  @each $_size, $_multiplier in $__spacing {
    @each $_axis, $_axis-shortname in $axes {
      @if $_breakpoint == 'root' {
        .#{$helper-ns}#{$_axis-shortname}-#{$_size} {
          #{$_axis}: $_multiplier !important;
        }
      } @else {
        @media only screen and (min-width: map-get($_breakpoint-val, 'breakpoint')) {
          .#{$_breakpoint}\:#{$helper-ns}#{$_axis-shortname}-#{$_size} {
            #{$_axis}: $_multiplier !important;
          }
        }
      }
    }
  }
}