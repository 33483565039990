.rn-textinput {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  margin: spacing(3) 0;
  padding: 0;
  border: 0;
  vertical-align: top;
  width: 100%;
}

.rn-textinput__outer-wrapper {
  display: inline-flex;
  flex-direction: row;
  background-color: color(neutral, white);
  border: 1px solid color(neutral, 200);
  border-radius: 4px;
  transition:
    border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.rn-textinput.has-focus .rn-textinput__outer-wrapper {
  box-shadow: 0 0 0 1px color(primary, 600);
  border: 1px solid color(primary, 600);
  background: color(neutral, white);
}

.rn-textinput.is-invalid .rn-textinput__outer-wrapper {
  border-color: color(danger, 600);
  box-shadow: 0 0 0 1px color(danger, 600);
}

.rn-textinput.is-valid .rn-textinput__outer-wrapper {
  border-color: color(success, 700);
  box-shadow: 0 0 0 1px color(success, 700);
}

.rn-textinput__input-wrapper {
  position: relative;
  flex-grow: 1;
  order: 2;
}

.rn-textinput__label {
  display: block;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  transform: translate(spacing(3), spacing(3)) scale(1);
  transition:
    color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  pointer-events: none;
  color: color(neutral, 300);
  font-size: font-size(s);
}

.rn-textinput.has-focus .rn-textinput__label,
.rn-textinput.has-content .rn-textinput__label {
  transform: translate(spacing(3), 6px) scale(0.8);
}

.rn-textinput__input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: spacing(5) spacing(3) spacing(1);
  border: 0;
  background: none;
  -webkit-tap-highlight-color: transparent;
  font-size: font-size(s);
}

.rn-textinput--no-label .rn-textinput__input {
  padding: spacing(3);
}

.rn-textinput__input:focus {
  outline: 0;
}

.rn-textinput__start-adornment,
.rn-textinput__end-adornment {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: spacing(3);
}

.rn-textinput__start-adornment {
  order: 1;
  padding-right: 0;
}

.rn-textinput__end-adornment {
  order: 3;
  padding-left: 0;
}
