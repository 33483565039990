$btn-border-radius: 3px;
$btn-font-weight: 500;
$btn-disabled-opacity: 0.4;
$btn-block-spacing-y: 1rem;
$animation-timing: 0.3s;
$btn-focus-width: 0.2rem;

.rn-btn {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  color: color(neutral, 500);
  background-color: color(neutral, white);
  border: 1px solid transparent;
  border-radius: $btn-border-radius;
  margin: spacing(1) 0;
  outline: 0;
  padding: spacing(3) spacing(4);
  font-size: font-size(s);
  font-weight: $btn-font-weight;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  transition: all $animation-timing;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    background-color: color(neutral, 100);
  }

  &:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba(color(primary, 700) , 0.5);
  }
}

.rn-btn + .rn-btn {
  margin-left: spacing(2);
}

.rn-btn--primary {
  background: color(primary, 600);
  color: color(neutral, white);

  &:hover {
    background: color(primary, 800);
  }
}

.rn-btn--secondary {
  border: 1px solid color(neutral, 200);
}

.rn-btn--danger {
  color: color(danger, 600);

  &:hover {
    color: color(danger, 600);
  }

  &:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba(color(danger, 800) , 0.5);
  }
}

.rn-btn--danger.rn-btn--primary {
  background: color(danger, 500);
  color: color(neutral, white);

  &:hover {
    background: color(danger, 800);
    color: color(neutral, white);
  }
}

.rn-btn__icon {
  display: inline-flex;
  align-items: center;
  margin-left: spacing(2);
}

.rn-btn.rn-btn--regular {
  .rn-btn__icon svg {
    width: 15px;
    height: 15px;
  }
}

.rn-btn.rn-btn--small {
  font-size: font-size(xs);
  padding: spacing(2) spacing(3);

  .rn-btn__icon svg {
    width: 13px;
    height: 13px;
  }
}

.rn-btn.rn-btn--large {
  font-size: font-size(s);
  padding: spacing(3) spacing(5);

  .rn-btn__icon svg {
    width: 15px;
    height: 15px;
  }
}

.rn-btn.rn-btn--xlarge {
  font-size: font-size(m);
  padding: spacing(3) spacing(5);

  .rn-btn__icon svg {
    width: 20px;
    height: 20px;
  }
}

.rn-btn.disabled,
.rtn-btn:disabled,
fieldset:disabled a.rn-btn {
  pointer-events: none;
}

.rn-btn.rn-btn--disabled,
.rn-btn.rn-btn--disabled:hover,
.rn-btn.rn-btn--disabled:focus {
  background: color(neutral, 000);
  color: color(neutral, 500);
  cursor: not-allowed;
}
