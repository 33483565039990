$active-border-color: color(primary, 600);
$btn-focus-width: 0.2rem;

.rn-tab-nav {
  padding-left: spacing(3);
  padding-right: spacing(3);
  background: color(neutral, white);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid color(neutral, 100)
}

.rn-tab-nav__tabs {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.rn-tab-nav__item {
  border-bottom: 4px solid transparent;
  color: color(neutral, 300);
  margin-bottom: 0;
  text-align: center;

  &.is-active {
    color: color(neutral, 400);
    border-color: $active-border-color;
  }
}

.rn-tab-nav__link {
  display: block;
  margin: 0 spacing(1);
  padding: spacing(4) spacing(8);
  border: none;
  font-size: font-size(xs);
  font-weight: 700;
  color: inherit;
  text-align: center;
  text-decoration: none;

  &:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba(color(primary, 700) , 0.5);
  }
}


@include breakpoint("s") {
  .rn-tab-nav {
    display: flex;
    flex-flow: column;
  }

  .rn-tab-nav__item,
  .rn-tab-nav__link {
    display: inline-block;
  }
}
