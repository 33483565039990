.rn-popover {
  pointer-events: none;
  opacity: 0;
  transition: linear opacity 0.3s;

  &.is-visible {
    pointer-events: all;
    opacity: 1;
  }
}
