/* stylelint-disable */

// Visibility Helper

@mixin visibilitySharedStyles {
  &visible {
    visibility: visible !important;
  }
  &hidden {
    visibility: hidden !important;
  }
  &none {
    display: none !important;
  }
}


@each $_breakpoint, $_breakpoint-val in $__breakpoints {
  @if $_breakpoint == 'root' {
    .#{$helper-ns} {
      @include visibilitySharedStyles;
    }
  } @else {
    @media only screen and (min-width: map-get($_breakpoint-val, 'breakpoint')) {
      .#{$_breakpoint}\:#{$helper-ns} {        
          @include visibilitySharedStyles;
      }
    }
  }
}