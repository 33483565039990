$active-border-color: color(primary, 600);
$btn-focus-width: 0.2rem;

.rn-tab-set {
  display: flex;
  flex-flow: column;

  &.is-scrollable {
    .rn-tab-set__navigation {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
    }

    .rn-tab-set__head {
      display: flex;
      flex-direction: row;
      padding-bottom: spacing(2);
    }

    .rn-tab-set__tab-item {
      width: 100%;
      padding-left: spacing(1);
    }

    .rn-tab-set__tab-item:last-child {
      padding-right: spacing(1);
    }

    .rn-tab-set__tab {
      width: 100%;
      border: none;
      height: spacing(10);
      padding: unset;
      border-radius: 6px;
      background-color: color("neutral", 100);

      &.is-active {
        background-color: color("primary", 300);
        color: color("neutral", 000)
      }
    }
  }
}

.rn-tab-set__head {
  border-bottom: 1px solid color(neutral, 100);
}

.rn-tab-set__scroll {
  width: spacing(6);
  height: spacing(12);
  background: color("neutral", white);
  border: 1px solid color("neutral", 100);
  border-radius: 3px;
  color: color("neutral", 300);
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: unset;
}

.rn-tab-set__scroll--left {
  margin-right: spacing(1);
}

.rn-tab-set__scroll--right {
  margin-left: spacing(1);
}

.rn-tab-set__tabs {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.rn-tab-set__tab-item {
  display: inline-block;
  margin-top: spacing(1);
}

.rn-tab-set__tab {
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  font-size: font-size(xs);
  font-weight: 700;
  color: color("neutral", 300);

  // default
  border: none;
  border-bottom: 4px solid transparent;
  padding: spacing(4) spacing(8);

  & > div {
    margin: 0 auto;
  }

  &:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba(color(primary, 700) , 0.5);
  }

  &.is-active {
    color: color("neutral", 400);
    border-color: $active-border-color;
  }
}

.rn-tab-set__body {
  padding: spacing(4) 0;
}

.rn-tab-set__content {
  display: none;

  // Fix to ensure the first level SVGs in a content document have appropriate
  // padding underneath their headings.
  > svg {
    width: 100%;
    margin-top: spacing(4);

    + ul, + ol {
      margin-left: 16px;
      padding-left: 0;
    }
  }

  &.is-active {
    display: block;
  }
}

@include breakpoint("m") {
  .rn-tab-set {
    &.is-scrollable {
      .rn-tab-set__tab-item {
        width: auto;
      }

      .rn-tab-set__tab {
        width: auto;
        padding: 0 spacing(8);
      }
    }
  }
}
