/* stylelint-disable */

$rules: (
  margin: m,
  padding: p
);

$sides: (
  top: t,
  right: r,
  bottom: b,
  left: l
);

// Build Spacing Helper Classes
@each $_breakpoint, $_breakpoint-val in $__breakpoints {
  @each $_size, $_multiplier in $__spacing {
    @each $_rule, $_rule-shortname in $rules {
      
      @if $_breakpoint == 'root' {        
        .#{$helper-ns}#{$_rule-shortname}-#{$_size} {        
            #{$_rule}: $_multiplier !important;
        }
      } @else {
        .#{$_breakpoint}\:#{$helper-ns}#{$_rule-shortname}-#{$_size} {        
          @media only screen and (min-width: map-get($_breakpoint-val, 'breakpoint')) {
            #{$_rule}: $_multiplier !important;
          }
        }
      }

    }
    @each $_property, $_shortname in $sides {
      @each $_rule, $_rule-shortname in $rules {

        @if $_breakpoint == 'root' {        
          .#{$helper-ns}#{$_rule-shortname}#{$_shortname}-#{$_size} {
            #{$_rule}-#{$_property}: $_multiplier !important;
          }
        } @else {
          .#{$_breakpoint}\:#{$helper-ns}#{$_rule-shortname}#{$_shortname}-#{$_size} {
            @media only screen and (min-width: map-get($_breakpoint-val, 'breakpoint')) {
              #{$_rule}-#{$_property}: $_multiplier !important;
            }
          }
        }
      }
    }
  }
}