$border-thick: 2px;
$inner: 6px;
$outer: $inner + 1;
$offset: $outer + $border-thick;

.rn-dropdown {
  .rn-dropdown__control {
    border-color: color(neutral, 200);
  }

  .rn-dropdown__placeholder {
    color: color(neutral, 500);
  }

  .rn-dropdown__control--is-focused {
    border-color: color(primary, 600);
    box-shadow: 0 0 0 1px color(primary, 600);
  }

  .rn-dropdown__dropdown-indicator {
    padding-left: (40px - 11px)/2;
    padding-right: (40px - 11px)/2;
  }

  .rn-dropdown__indicator-separator {
    display: none;
  }

  .rn-dropdown__menu {
    @include shadow(1);
    top: 102%;
    margin: spacing(3) 0 0 5%;
    width: 95%;
  }

  .rn-dropdown__menu-list {
    border: 2px solid color(primary, 600);
    border-radius: 4px;
    padding: spacing(1);
  }

  .rn-dropdown__menu:before {
    display: block;
    border-style: solid;
    border-width: 0 7px 7px;
    border-color: color(primary, 600) transparent;
    position: absolute;
    top: -7px;
    left: 95%;
    width: 0;
    margin-left: -7px;
    z-index: 0;
    content: "";
  }

  .rn-dropdown__menu:after {
    display: block;
    border-style: solid;
    border-width: 0 6px 6px;
    border-color: color(neutral, white) transparent;
    position: absolute;
    top: -4px;
    left: 95%;
    width: 0;
    margin-left: -6px;
    z-index: 1;
    content: "";
  }

  .rn-dropdown__option,
  .rn-dropdown__option--is-selected,
  .rn-dropdown__option--is-focused {
    color: color(neutral, 500);
  }

  .rn-dropdownlabel.is-disabled {
    opacity: 0.25;
  }

  .rn-dropdown__option {
    border-radius: 4px;
    padding: spacing(3) spacing(2);
  }

  .rn-dropdown__option--is-selected {
    background-color: color(neutral, white);
  }

  .rn-dropdown__option--is-focused ,
  .rn-dropdown__option--is-focused.rn-dropdown__option--is-selected {
    background-color: color(neutral, 100);
  }

  .rn-dropdown__single-value .rn-dropdownlabel .rn-dropdownlabel__end-adornment {
    display: none;
  }

  .rn-dropdownlabel,
  .rn-dropdownlabel__start-adornment,
  .rn-dropdownlabel__end-adornment {
    display: flex;
    align-items: center;
  }

  .rn-dropdownlabel__start-adornment {
    padding-right: spacing(2);
  }

  .rn-dropdownlabel__label {
    flex: 1;
  }

  .rn-arrow__fill {
    fill: color(neutral, 200);
  }

  .rn-arrow {
    transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transform: translateY(1px);

    .rn-arrow__fill {
      transition: fill 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }

  .rn-dropdown__control--menu-is-open {
    .rn-arrow {
      transform: rotate(180deg) translateY(1px);
    }

    .rn-arrow__fill {
      fill: color(primary, 600);
    }
  }
}
