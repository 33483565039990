$animation-timing: 0.2s;
$btn-focus-width: 0.1rem;

.rn-radio {
  display: block;
  position: relative;
  padding-left: spacing(5);
  margin-bottom: spacing(2);
  cursor: pointer;
  font-size: font-size(s);
  user-select: none;
}

.rn-radio__outer-wrapper {
  display: inline-flex;
  flex-direction: row;
}

.rn-radio__label {
  color: color(neutral, 400);
}

.rn-radio .rn-radio__radio {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.rn-radio__checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: color(neutral, white);
  border-radius: 999px;
  border: 1px solid color(neutral, 200);
}

/* Active state, light blue outline */
.rn-radio input:hover ~ .rn-radio__checkmark,
.rn-radio input:active ~ .rn-radio__checkmark {
  border: 1px solid color(primary, 500);
  outline: none;
  box-shadow: 0 0 0 $btn-focus-width color(primary, 500);
  transition: all $animation-timing;
}

/* Checked state, blue border */
.rn-radio input:checked ~ .rn-radio__checkmark {
  background-color: color(neutral, white);
  border: 1px solid color(primary, 500);
  box-shadow: 0 0 0 $btn-focus-width color(primary, 500);
}

.rn-radio__checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.rn-radio input:checked ~ .rn-radio__checkmark:after {
  display: block;
}

/* Checkmark, blue dot */
.rn-radio .rn-radio__checkmark:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 999px;
  background: color(primary, 600);
  border: 3px solid color(neutral, white);
}

.rn-radio__invalid-feedback {
  color: color(danger, 600);
  margin: spacing(2) spacing(3);
  font-size: font-size(s);
}
