/* stylelint-disable */
@mixin positionSharedStyles {
  &static {
    position: static !important;
  }
  &relative {
    position: relative !important;
  }
  &absolute {
    position: absolute !important;
  }
  &fixed {
    position: fixed !important;
  }
  &sticky {
    position: sticky !important;
  }
}


@each $_breakpoint, $_breakpoint-val in $__breakpoints {
  @if $_breakpoint == 'root' {
    .#{$helper-ns} {
      @include positionSharedStyles;
    }
  } @else {
    @media only screen and (min-width: map-get($_breakpoint-val, 'breakpoint')) {
      .#{$_breakpoint}\:#{$helper-ns} {        
          @include positionSharedStyles;
      }
    }
  }
}