$_alert-background-colour: color("primary", 000);
$_alert-border-colour: color("primary", 500);
$_alert-title-colour: color("primary", 800);
$_alert-close-colour: color("primary", 500);
$_alert-description-colour: color("primary", 600);

// Danger
$_danger-alert-background-colour: color("danger", 000);
$_danger-alert-border-colour: color("danger", 500);
$_danger-alert-title-colour: color("danger", 800);
$_danger-alert-close-colour: color("danger", 500);
$_danger-alert-description-colour: color("danger", 600);

// Success
$_success-alert-background-colour: color("success", 000);
$_success-alert-border-colour: color("success", 500);
$_success-alert-title-colour: color("success", 800);
$_success-alert-close-colour: color("success", 500);
$_success-alert-description-colour: color("success", 600);

// Warning
$_warning-alert-background-colour: color("warning", 000);
$_warning-alert-border-colour: color("warning", 500);
$_warning-alert-title-colour: color("warning", 800);
$_warning-alert-close-colour: color("warning", 500);
$_warning-alert-description-colour: color("warning", 600);

.rn-alert {
  background-color: $_alert-background-colour;
  border-radius: 0px 4px 4px 0px;
  border-left: 4px solid $_alert-border-colour;
  padding: 16px;

  &--danger {
    background-color: $_danger-alert-background-colour;
    border-left-color: $_danger-alert-border-colour;
  }

  &--success {
    background-color: $_success-alert-background-colour;
    border-left-color: $_success-alert-border-colour;
  }

  &--warning {
    background-color: $_warning-alert-background-colour;
    border-left-color: $_warning-alert-border-colour;
  }
}

.rn-alert__header {
  display: flex;
  margin-bottom: spacing(1);
}

.rn-alert__icon {
  display: inline-flex;
  align-items: center;
  color: $_alert-title-colour;
  padding-right: 6px;

  &--danger {
    color: $_danger-alert-title-colour;
  }

  &--success {
    color: $_success-alert-title-colour;
  }

  &--warning {
    color: $_warning-alert-title-colour;
  }
}

.rn-alert__title {
  color: $_alert-title-colour;
  font-size: font-size(s);
  font-weight: bold;

  &--danger {
    color: $_danger-alert-title-colour;
  }

  &--success {
    color: $_success-alert-title-colour;
  }

  &--warning {
    color: $_warning-alert-title-colour;
  }
}

.rn-alert__close {
  position: absolute;
  top: spacing(1);
  right: spacing(1);
  border: none;
  font-size: font-size(l);
  color: $_alert-close-colour;
  background-color: $_alert-background-colour;

  &:hover {
    cursor: pointer;
  }

  &--danger {
    color: $_danger-alert-close-colour;
    background-color: $_danger-alert-background-colour;
  }

  &--success {
    color: $_success-alert-close-colour;
    background-color: $_success-alert-background-colour;
  }

  &--warning {
    color: $_warning-alert-close-colour;
    background-color: $_warning-alert-background-colour;
  }
}

.rn-alert__content {
  display: inline-flex;
  align-items: center;
}

.rn-alert__description {
  color: $_alert-description-colour;
  font-size: font-size(xs);

  &--danger {
    color: $_danger-alert-description-colour;
  }

  &--success {
    color: $_success-alert-description-colour;
  }

  &--warning {
    color: $_warning-alert-description-colour;
  }
}
