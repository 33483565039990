$masthead-main-height: 58px;

.rn-masthead {
  width: 100%;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1 );
  background-color: color(neutral, white);
}

.rn-masthead__main {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid color(neutral, 100);
}

.rn-masthead__service-name {
  display: flex;
  height: $masthead-main-height;
  color: color(primary, 900);
  font-weight: bold;
  align-items: center;
  text-decoration: none;
  margin-left: spacing(5);
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba(color(primary, 700) , 0.5);
  }

  .rn-masthead__title {
    margin-left: spacing(2);
  }
}

.rn-masthead__options {
  display: flex;
}

.rn-masthead__option {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $masthead-main-height;
  width: $masthead-main-height;
  margin: 0;

  text-decoration: none;
  background-color: color(neutral, white);
  border: none;
  cursor: pointer;
  transition: background-color 300ms;

  &:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba(color(primary, 700) , 0.5);
  }

  .rn-iconfill {
    fill: color(neutral, 400);
  }
}

.rn-masthead__vertical-separator {
  height: $masthead-main-height;
  width: 2px;

  > * {
    stroke: color(neutral, 100);
    stroke-width: 2px;
  }
}

.rn-masthead--show-search .rn-scrollable-nav__scroll-container,
.rn-masthead--show-notifications .rn-scrollable-nav__scroll-container {
  -webkit-overflow-scrolling: auto;
}
