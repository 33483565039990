.rn-table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  th {
    font-weight: bold;
    text-align: left;
    @include font-size(xs);
    text-transform: uppercase;
    padding: spacing(4) spacing(2);

    &.is-sortable {
      cursor: pointer;
    }

    & svg {
      position: relative;
      left: spacing(1);
      top: 2px;
      width: spacing(3);
      height: spacing(3);
    }
  }
  thead {
    border-bottom: 2px solid color(neutral, 100);
  }
  tr {
    border-bottom: 1px solid color(neutral, 100);
    &:last-child {
      border-bottom: none;
    }
  }
  td {
    @include font-size(xs);
    padding: spacing(2) spacing(2);
    color: color(neutral, 500);
  }

  &__arrow {
    margin-left: spacing(2);
    &--down {
      transform: rotate(180deg);
      margin-left: -3px;
    }
    opacity: 0.4;
  }

  .sorted {
    &.sort-asc .rn-table__arrow--down {
      opacity: 1;
    }
    &.sort-desc .rn-table__arrow--up {
      opacity: 1;
    }
  }
}

.rn-table__wrapper {
  overflow: scroll;
  overflow-y: hidden;
}
