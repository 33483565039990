$tooltipDarkBackground: color(neutral, 700);
$tooltipDarkBorder: color(neutral, 700);
$tooltipBorderThick: 2px;
$tooltipInner: 8px;
$tooltipOuter: $tooltipInner + 1;
$tooltipOffset: $tooltipOuter + $tooltipBorderThick;

.rn-tooltip {
  position: absolute;
}

.rn-tooltip__message {
  font-size: font-size(s);
  color: color(neutral, 100);
}

.rn-tooltip__content {
  @include z-index(modal);
  background: $tooltipDarkBackground;
  color: color(neutral, white);
  border: $tooltipDarkBorder solid $tooltipBorderThick;
  border-radius: 5px;
  padding: spacing(3);
  position: relative;
  top: auto;
  left: auto;
  height: auto;
  width: auto;
}

.rn-tooltip__title {
  padding-bottom: spacing(1);
}

.rn-tooltip__content:before {
  border-style: solid;
  content: "";
  display: block;
  position: absolute;
  width: 0;
  z-index: 0;
}

.rn-tooltip__content:after {
  border-style: solid;
  content: "";
  display: block;
  position: absolute;
  width: 0;
  z-index: 1;
}

.rn-tooltip--below .rn-tooltip__content:before {
  border-color: $tooltipDarkBorder transparent;
  border-width: 0 $tooltipOuter $tooltipOuter;
  margin-left: -$tooltipOuter;
  top: -$tooltipOffset;
  left: 50%;
}

.rn-tooltip--below .rn-tooltip__content:after {
  border-color: $tooltipDarkBackground transparent;
  border-width: 0 $tooltipInner $tooltipInner;
  margin-left: -$tooltipInner;
  top: -$tooltipInner;
  left: 50%;
}


.rn-tooltip--above .rn-tooltip__content:before {
  border-color: $tooltipDarkBorder transparent;
  border-width: $tooltipInner $tooltipInner 0;
  margin-left: -$tooltipOuter;
  bottom: -$tooltipInner - $tooltipBorderThick;
  left: 50%;
}

.rn-tooltip--above .rn-tooltip__content:after {
  border-color: $tooltipDarkBackground transparent;
  border-width: $tooltipInner $tooltipInner 0;
  margin-left: -$tooltipOuter;
  bottom: -$tooltipOuter;
  left: 50%;
}

.rn-tooltip--right .rn-tooltip__content:before {
  border-color: transparent $tooltipDarkBorder;
  border-width: $tooltipOuter $tooltipOuter $tooltipOuter 0;
  margin-top: -$tooltipOuter;
  left: -$tooltipOffset;
  top: 50%;
}

.rn-tooltip--right .rn-tooltip__content:after {
  border-color: transparent $tooltipDarkBackground;
  border-width: $tooltipInner $tooltipInner $tooltipInner 0;
  margin-top: -$tooltipInner;
  left: -$tooltipInner;
  top: 50%;
}

.rn-tooltip--left .rn-tooltip__content:before {
  border-color: transparent $tooltipDarkBorder;
  border-width: $tooltipOuter 0 $tooltipOuter $tooltipOuter;
  margin-top: -$tooltipOuter;
  right: -$tooltipOffset;
  top: 50%;
}

.rn-tooltip--left .rn-tooltip__content:after {
  border-color: transparent $tooltipDarkBackground;
  border-width: $tooltipInner 0 $tooltipInner $tooltipInner;
  margin-top: -$tooltipInner;
  right: -$tooltipInner;
  top: 50%;
}

