$range-slider-bg-colour: color(neutral, 100);
$range-slider-track-colour: color(primary, 500);
$range-slider-handle-colour: color(primary, 500);

.rn-rangeslider {
  display: inline-flex;
  align-items: center;
  width: 100%;

  > div {
    position: relative;
    width: 100%;
    height: 38px;
  }

  &.is-disabled {
    opacity: 0.5;

    .rn-rangeslider__rail-outer,
    .rn-rangeslider__track {
      cursor: not-allowed;
    }
  }
}

.rn-rangeslider__icon {
  color: color(neutral, 400);
  overflow: visible;
}

.rn-rangeslider__icon--left {
  margin-right: spacing(2);
}

.rn-rangeslider__icon--right {
  margin-left: spacing(2);
}

.rn-rangeslider__rail {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 2px;
  top: 0.3rem;
}

.rn-rangeslider__rail-outer {
  position: absolute;
  width: 100%;
  height: 40px;
  transform: translate(0%, -50%);
  cursor: pointer;
}

.rn-rangeslider__rail-inner {
  position: absolute;
  width: 100%;
  height: 2px;
  transform: translate(0%, -50%);
  background-color: $range-slider-bg-colour;
  pointer-events: none;
}

.rn-rangeslider__handle {
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 14px;
  height: 14px;
  margin-left: 1px;
  border: none;
  border-radius: 9999px;
  background-color: $range-slider-handle-colour;
  text-align: center;
  box-shadow: 0px 0px 0px 0px rgba(color(primary, 200), 0.5);
  transition: box-shadow 0.15s ease-in-out;
  cursor: pointer;

  &::after {
    content: attr(data-value);
    position: absolute;
    transform: translate(-50%, -225%);
    font-size: font-size(xs);
    color: color(neutral, 600);
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
  }

  &.is-active, &:focus {
    box-shadow: 0px 0px 0px 7px rgba(color(primary, 200), 0.5);
    outline: none;

    &::after {
      opacity: 1;
    }
  }
}

.rn-rangeslider__track {
  position: absolute;
  transform: translate(0%, -50%);
  height: 2px;
  z-index: 1;
  background-color: $range-slider-track-colour;
  cursor: pointer;
}

.rn-rangeslider__ticks {
  div:first-of-type,
  div:last-of-type {
    .rn-rangeslider__tick {
      height: 16px;
    }
  }
}

.rn-rangeslider__tick {
  position: absolute;
  width: 2px;
  height: 12px;
  transform: translateY(-50%);
  background-color: $range-slider-bg-colour;

  &.is-active {
    background-color: $range-slider-track-colour;
  }
}

.rn-rangeslider__label {
  position: absolute;
  margin-top: 22px;
  font-size: font-size(xs);
  color: color(neutral, 300);
  text-align: center;
}
