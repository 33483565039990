/* stylelint-disable */

/**************************************************************************

    Font Size Helpers
    =================

    Generates helper classes for font size.

**************************************************************************/



@each $_breakpoint, $_breakpoint-val in $__breakpoints {
  @each $_shortname, $_value in $__typography {
    @if $_breakpoint == 'root' {
      .#{$helper_ns}text-#{$_shortname} {
        font-size: $_value !important;
      }
    } @else {
      @media only screen and (min-width: map-get($_breakpoint-val, 'breakpoint')) {
        .#{$_breakpoint}\:#{$helper_ns}text-#{$_shortname} {
          font-size: $_value !important;
        }
      }
    }
  }
}



/**************************************************************************

    Text Aligning Helpers

**************************************************************************/

@mixin textSharedStyles {
  &-left {
    text-align: left !important;
  }
  &-center {
    text-align: center !important;
  }
  &-right {
    text-align: right !important;
  }
}

@each $_breakpoint, $_breakpoint-val in $__breakpoints {
  @if $_breakpoint == 'root' {
    .#{$helper-ns}text {
      @include textSharedStyles;
    }
  } @else {
    @media only screen and (min-width: map-get($_breakpoint-val, 'breakpoint')) {
      .#{$_breakpoint}\:#{$helper-ns}text {
          @include textSharedStyles;
      }
    }
  }
}