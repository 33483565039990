/* stylelint-disable */
@mixin flexSharedStyles {
  display: flex !important;

  // Direction
  &-row {
    flex-direction: row !important;
    &-reverse {
      flex-direction: row-reverse !important;
    }
  }
  &-col {
    flex-direction: column !important;
    &-reverse {
      flex-direction: column-reverse !important;
    }
  }

  // Alignment
  &-align {
    &-stretch {
      align-items: stretch !important;
    }
    &-start {
      align-items: flex-start !important;
    }
    &-center {
      align-items: center !important;
    }
    &-end {
      align-items: flex-end !important;
    }
  }

  // Content
  &-justify {
    &-stretch {
      justify-content: stretch !important;
    }
    &-start {
      justify-content: start !important;
    }
    &-center {
      justify-content: center !important;
    }
    &-end {
      justify-content: end !important;
    }
  }

  &-1 {
    flex: 1 !important;
  }
}

@each $_breakpoint, $_breakpoint-val in $__breakpoints {
  @if $_breakpoint == 'root' {
    .#{$helper-ns}f {
      @include flexSharedStyles;      
    }
  } @else {
    @media only screen and (min-width: map-get($_breakpoint-val, 'breakpoint')) {
      .#{$_breakpoint}\:#{$helper-ns}f {        
          @include flexSharedStyles;
      }
    }
  }
}