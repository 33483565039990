.rn-breadcrumbs {
  display: block;
  font-size: font-size(s);
  margin: 0;
  padding: 0;
  list-style: none;
}

.rn-breadcrumbs__breadcrumb {
  display: inline-block;
}

.rn-breadcrumbs__link {
  display: inline-block;
  color: color(neutral, 400);
  font-weight: 400;
  text-decoration: none;
}

.rn-breadcrumbs__link:hover {
  color: color(primary, 500);
}

.rn-breadcrumbs__breadcrumb:hover ~ .rn-breadcrumbs__breadcrumb {
  opacity: 0.2;
}

.rn-breadcrumbs__title {
  font-weight: 700;
  cursor: default;
}

.rn-breadcrumbs__seperator {
  display: inline-block;
  padding: 0 spacing(2);
}

