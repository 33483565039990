.rn-textarea {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  padding: 0;
  border: 0;
  vertical-align: top;
  width: 100%;
}

.rn-textarea__wrapper {
  background-color: color(neutral, white);
  border: 1px solid color(neutral, 200);
  border-radius: 4px;
  transition:
    border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: relative;
}

.rn-textarea.has-focus .rn-textarea__wrapper {
  box-shadow: 0 0 0 1px color(primary, 600);
  border: 1px solid color(primary, 600);
  background: color(neutral, white);
}

.rn-textarea.is-invalid .rn-textarea__wrapper {
  border-color: color(danger, 600);
  box-shadow: 0 0 0 1px color(danger, 600);
}

.rn-textarea.is-valid .rn-textarea__wrapper {
  border-color: color(success, 700);
  box-shadow: 0 0 0 1px color(success, 700);
}


.rn-textarea__label {
  display: block;
  z-index: 1;
  position: absolute;
  padding-top: 6px;
  padding-left: spacing(3);
  padding-bottom: spacing(1);
  right: 14px;
  left: 0;
  pointer-events: none;
  color: color(neutral, 300);
  font-size: font-size(xs);
  background-color: white;
  border-radius: 3px 3px 0 0;
}

.rn-textarea__label-inner {
  display: inline-block;
  transform-origin: top left;
  transform: translate(0, spacing(1)) scale(1.2);
  transition:
    color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.rn-textarea.has-focus .rn-textarea__label-inner,
.rn-textarea.has-content .rn-textarea__label-inner {
  transform: none;
}

.rn-textarea__input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: spacing(5) spacing(3) spacing(1);
  border: 0;
  background: none;
  font-size: font-size(s);
  resize: vertical;
  overflow: auto;
  min-height: 80px;
  -webkit-tap-highlight-color: transparent;
}

.rn-textarea--no-label .rn-textarea__input {
  padding: spacing(3);
}

.rn-textarea__input:focus {
  outline: 0;
}
