$animation-timing: 0.2s;
$btn-focus-width: 1px;

.rn-checkbox {
  display: block;
  position: relative;
  padding-left: spacing(6);
  margin-bottom: spacing(3);
  font-size: font-size(s);
  user-select: none;
}

.rn-checkbox__outer-wrapper {
  display: inline-flex;
  flex-direction: row;
}

.rn-checkbox__label {
  color: color(neutral, 400);
}

.rn-checkbox .rn-checkbox__checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.rn-checkbox__checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: color(neutral, white);
  border: 1px solid color(neutral, 200);
  border-radius: 4px;
}

/* Hover state, light blue outline */
.rn-checkbox input:hover ~ .rn-checkbox__checkmark,
.rn-checkbox input:active ~ .rn-checkbox__checkmark {
  border: 1px solid color(primary, 500);
  outline: none;
  box-shadow: 0 0 0 $btn-focus-width color(primary, 500);
  transition: all $animation-timing;
}

/* Checked state, solid blue */
.rn-checkbox input:checked ~ .rn-checkbox__checkmark {
  background-color: color(primary, 600);
  border: 1px solid color(primary, 600);
  text-align: center;
}

.rn-checkbox__checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.rn-checkbox input:checked ~ .rn-checkbox__checkmark:after {
  display: block;
}

/* Checkmark, white tick */
.rn-checkbox .rn-checkbox__checkmark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOC44MjIgMGMxLjA1LS4wMjcgMS41NzQgMS4zMDQuODI3IDIuMDhMNC4yOTggNy43NGEuODMuODMgMCAwIDEtMS4yMDcgMEwuMzc2IDQuODU1Qy0uNzUyIDMuNzE4LjkxNCAxLjk1NiAxLjk5IDMuMTQ5bDEuNTA4IDEuNTk1Yy4xMDUuMTEuMjg4LjExLjQwNyAwbDQuMTMtNC4zN0ExLjEgMS4xIDAgMCAxIDguODIzIDB6IiBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=);
  top: 0;
  left: 0;
  transform: translateY(-1px);
  width: 100%;
  height: 100%;
  line-height: 1;
  color: color(neutral, white);
  border-radius: 2px;
}

.rn-checkbox__invalid-feedback {
  color: color(danger, 600);
  margin: spacing(2) spacing(3);
  font-size: font-size(s);
}
