.rn-phase-banner {
  background-color: color(primary, 100);
  padding-top: spacing(2);
  padding-bottom: spacing(2);
}

.rn-phase-banner__text {
  display: inline-block;
  font-size: font-size(xs);
  vertical-align: middle;
  margin-left: spacing(2);
  color: color(neutral, 800);
}

.rn-phase-banner__text a {
  color: color(neutral, black);
  text-decoration: underline;
}

.rn-phase-banner .rn-badge {
  margin: 0;
  text-transform: capitalize;
  margin-right: spacing(1);
}
