.rn-modal {
  @include z-index(modal);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);

  &.is-closed {
    display: none;
  }

  &.is-open {
    display: block;
  }
}

.rn-modal__main {
  @include shadow(2);
  border: 1px solid color(neutral, 200);
  border-radius: 5px;
  background: color(neutral, white);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 90%;
  @include breakpoint(m) {
    width: 100%;
    max-width: 700px;
  }
}

.rn-modal__header {
  display: flex;
  justify-content: space-between;
  padding: spacing(6);
  border-bottom: 1px solid color(neutral, 100);
}

.rn-modal__title {
  display: inline-block;
  font-size: font-size(m);
  color: color(neutral, 500);
}

.rn-modal__close-button {
  background: color(neutral, 000);
  border-radius: 9999px;
  border: 1px transparent;
  cursor: pointer;

  &:hover,
  &:focus {
    background: color(neutral, 100);
    outline: 0;
  }
}

.rn-modal .rn-modal__container {
  padding: spacing(4) spacing(4);
}

.rn-modal__footer {
  display: flex;
  padding: spacing(4);
  border-top: 1px solid color(neutral, 100);
}

.rn-modal .rn-btn.rn-modal__primary-button,
.rn-modal .rn-btn.rn-modal__secondary-button,
.rn-modal .rn-btn.rn-modal__tertiary-button {
  margin: 0;
}

.rn-modal .rn-btn.rn-modal__secondary-button {
  margin-left: auto;
}

.rn-modal .rn-btn.rn-modal__primary-button {
  margin-left: spacing(2);
}

.rn-modal__divider {
  border: 0;
  border-bottom: 1px solid color(neutral, 100);
  margin: 0;
}
