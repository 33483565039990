/* stylelint-disable */
// Shadows Helper


@each $_breakpoint, $_breakpoint-val in $__breakpoints {
  @each $_size, $_shadow in $__shadows {
    @if $_breakpoint == 'root' {
      .#{$helper-ns}-shadow-#{$_size} {
        box-shadow: #{$_shadow} !important;
      }
    } @else {
      @media only screen and (min-width: map-get($_breakpoint-val, 'breakpoint')) {
        .#{$_breakpoint}\:#{$helper-ns}shadow-#{$_size} {        
            box-shadow: #{$_shadow} !important;
        }
      }
    }
  }
}