.rn-layout {
  
  &.vertical {
    .rn-layout {
      &__app {
        padding-top: 71px;
      }
    }
  }

  &.vertical--tabs {
    .rn-layout {
      &__app {
        padding-top: 128px;
      }
    }
  }

  &.horizontal {
    .rn-layout {
      &__app {
        padding-left: 200px;
      }
    }
  }

}