.rn-container {
  max-width: $navy-content-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: spacing(8);
  padding-right: spacing(8);

  &.rn-container--small {
    padding-left: spacing(8);
    padding-right: spacing(8);
  }

  &.rn-container--large {
    padding-left: spacing(10);
    padding-right: spacing(10);
  }
}
