.rn-numberinput {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  margin: spacing(3) 0;
  padding: 0;
  border: 0;
  vertical-align: top;
  width: 100%;
}

.rn-numberinput__outer-wrapper {
  display: inline-flex;
  flex-direction: row;
  background-color: color(neutral, white);
  border: 1px solid color(neutral, 200);
  border-radius: 4px;
  transition:
    border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.rn-numberinput.has-focus .rn-numberinput__input-wrapper {
  border-radius: 4px;
  box-shadow:
    2px 2px 0 0 color(primary, 600),
    -2px -2px 0 0 color(primary, 600),
    2px -2px 0 0 color(primary, 600),
    -2px 2px 0 0 color(primary, 600);
}

.rn-numberinput.is-invalid .rn-numberinput__input-wrapper {
  border-radius: 4px;
  box-shadow:
    2px 2px 0 0 color(danger, 600),
    -2px -2px 0 0 color(danger, 600),
    2px -2px 0 0 color(danger, 600),
    -2px 2px 0 0 color(danger, 600);
}

.rn-numberinput.is-valid .rn-numberinput__input-wrapper {
  border-radius: 4px;
  box-shadow:
    2px 2px 0 0 color(success, 700),
    -2px -2px 0 0 color(success, 700),
    2px -2px 0 0 color(success, 700),
    -2px 2px 0 0 color(success, 700);
}

.rn-numberinput__input-wrapper {
  position: relative;
  flex-grow: 1;
}

.rn-numberinput__label {
  display: block;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  transform: translate(spacing(3), spacing(3)) scale(1);
  transition:
    color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  pointer-events: none;
  color: color(neutral, 300);
  font-size: font-size(s);
}

.rn-numberinput.has-focus .rn-numberinput__label,
.rn-numberinput.has-content .rn-numberinput__label {
  transform: translate(spacing(3), 6px) scale(0.8);
}

.rn-numberinput__input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: spacing(5) spacing(3) spacing(1);
  border: 0;
  background: none;
  -webkit-tap-highlight-color: transparent;
  font-size: font-size(s);
}

.rn-numberinput--no-label .rn-numberinput__input {
  padding: spacing(3);
}

.rn-numberinput__input:focus {
  outline: 0;
}

.rn-numberinput__increase > svg {
  transform: rotate(180deg);
}

.rn-numberinput__controls {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border-left: 1px solid color(neutral, 100);
}

.rn-numberinput__increase,
.rn-numberinput__decrease {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 42px;
  align-items: center;


  background: transparent;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;

  flex-grow: 1;

  &:focus {
    border-radius: 4px;
    box-shadow:
      2px 2px 0 0 color(primary, 600),
      -2px -2px 0 0 color(primary, 600),
      2px -2px 0 0 color(primary, 600),
      -2px 2px 0 0 color(primary, 600);
  }
}

.rn-numberinput__decrease {
  border-top: 1px solid color(neutral, 100);

  &:focus {
    border-color: transparent;
  }
}

.rn-numberinput__increase:focus + .rn-numberinput__decrease {
  border-color: transparent;
}

.rn-numberinput__start-adornment {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: spacing(3);
  order: 0;
  background-color: color(neutral, 000);
  border-right: 1px solid color(neutral, 200);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  color: color(neutral, 300);
  font-weight: 600;
  line-height: 1;

  > svg {
    color: color(neutral, 300);
  }
}
