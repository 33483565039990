.rn-autosuggest {
  max-width: 600px;
  position: relative;
}

.rn-autosuggest__input {
  width: 100%;
  padding: spacing(3);
  border-radius: 3px;
  @include font-size(s);
  border: 1px solid color(neutral, 400);
  transition: all 0.2s;
  outline: none;
  &:focus {
    border: 1px solid color(primary, 400);
    box-shadow: 0 0 0 1px color(primary, 400);
  }

  &.open {
    + .rn-autosuggest__suggestions {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}

.rn-autosuggest__suggestions {
  @include shadow(1);
  top: calc(100% + 8px);
  position: absolute;
  border: 1px solid color(neutral, 200);
  background-color: color(neutral, white);
  border-radius: 5px;
  padding: spacing(1) 0;
  min-width: 160px;
  opacity: 0;
  transform: translate3d(0, -10px, 0);
  transition: all 0.3s;
  max-height: 240px;
  overflow-y: scroll;
  &-list {
    padding: 0;
    margin: 0;
  }
}

.rn-autosuggest__suggestion {
  cursor: pointer;
  @include font-size(xs);    
  padding: spacing(2) spacing(3);
  display: flex;
  justify-content: space-between;
  color: color(neutral, 800);
  font-weight: 500;
  &:hover {
    color: color(neutral, 800);
    background-color: color(neutral, 000);
  }
  &--highlighted {
    background: skyblue
  }
}
