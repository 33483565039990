.rn-card {
  @include font-size(s);
  @include shadow(1);
  background-color: color(neutral, white);
  border: 1px solid color(neutral, 100);
  border-radius: 3px;

  > *:first-child {
    margin-top: 0;
  }

  &__header {
    background-color: color(neutral, 000);
    padding: spacing(4) spacing(6);
    border-bottom: 1px solid color(neutral, 100);
    @include font-size(s);
    color: color(neutral, 800);
    margin: 0;
  }

  &__body {
    padding: spacing(4);
    & + & {
      border-top: 1px solid color(neutral, 100);
    }
    p {
      @include font-size(s);
      color: color(neutral, 600);
      font-weight: 400;
      line-height: 1.6;
      margin: 0;
    }
  }

  .small {
    padding: spacing(4) spacing(5);

    .title {
      @include font-size(base);
    }
  }
}
