.rn-dialog {
  .rn-modal__main {
    padding: spacing(4);
  }

  .rn-modal__footer {
    padding: spacing(4) 0 0 0;
    margin-top: spacing(8);
  }
}

.rn-dialog__title {
  display: inline-block;
  padding: spacing(2) 0 0 spacing(2);
  font-size: font-size(m);
  color: color(neutral, 500);
}

.rn-dialog__description {
  padding-left: spacing(2);
  font-size: font-size(s);
  color: color(neutral, 400);
}
