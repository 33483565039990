$badge-border-radius: 3px;
$badge-font-weight: 600;

$badge-sizes: (
  small: (
    font-size: font-size(xxxs),
    padding: spacing(1) spacing(2)
  ),
  regular: (
    font-size: font-size(xxs),
    padding: spacing(1) spacing(2)
  ),
  large: (
    font-size: font-size(xs),
    padding: spacing(1) spacing(3)
  ),
  xlarge: (
    font-size: font-size(s),
    padding: spacing(1) spacing(3)
  )
);

$badge-states: (
  neutral: (
    color: color("neutral", 400),
    solid: color("neutral", 500),
    faded: color("neutral", 100)
  ),
  primary: (
    color: color("primary", 700),
    solid: color("primary", 500),
    faded: color("primary", 100)
  ),
  danger: (
    color: color("danger", 700),
    solid: color("danger", 500),
    faded: color("danger", 100)
  ),
  warning: (
    color: color("warning", 700),
    solid: color("warning", 600),
    faded: color("warning", 200)
  ),
  success: (
    color: color("success", 700),
    solid: color("success", 600),
    faded: color("success", 100)
  )
);

.rn-badge {
  display: inline-block;
  border-radius: $badge-border-radius;
  font-weight: $badge-font-weight;
  vertical-align: middle;
  text-transform: uppercase;
  line-height: 1;

  @each $state, $variation in $badge-states {
    &.rn-badge--#{$state} {
      color: color(neutral, white);
      background: map-get($variation, "solid");
    }
  }

  &--solid {
    @extend .rn-badge;
    @each $state, $variation in $badge-states {
      &.rn-badge--#{$state} {
        color: color(neutral, white);
        background: map-get($variation, "solid");
      }
    }
  }

  &--faded {
    @extend .rn-badge;
    @each $state, $variation in $badge-states {
      &.rn-badge--#{$state} {
        color: map-get($variation, "color");
        background: map-get($variation, "faded");
      }
    }
  }

  @each $size, $values in $badge-sizes {
    &.rn-badge--#{$size} {
      font-size: map-get($values, "font-size");
      padding: map-get($values, "padding");
    }
  }


  &.rn-badge--pill {
    border-radius: 100px;
  
    @each $size, $values in $badge-sizes {
      &.rn-badge--#{$size} {
        font-size: map-get($values, "font-size");
        padding: map-get($values, "padding");
      }
    }
  }
}
