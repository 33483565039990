$progress-states: (
  neutral: color("neutral", 700),
  primary: color("primary", 400),
  danger:  color("danger", 400),
  warning: color("warning", 400),
  success: color("success", 500)
);

.rn-progress {  
  box-sizing: border-box;
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  height: 8px;
  
  &.small {
    height: 4px;
  }
  
  &.large {
    height: 16px;
  }
  
  @each $state, $value in $progress-states {
    &.#{$state} {    
      .rn-progress__bar {
        background: $value;
      }
    }
  }
  
  &__bar {
    background-color: map-get($progress-states, "neutral");
    // Border radius far exceeds height of container to ensure
    // borders are rounded correctly
    border-radius: 100px;
    box-sizing: border-box;
    height: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    text-align: center;    
    color: color(neutral, white);
    @include font-size(xxs);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

