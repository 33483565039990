$navy--font-size: 16px !default;
$navy--font-family: "lato" !default;
$navy--context: "light" !default;

// Contexts
$navy--breakpoints: () !default;
$navy--colors: () !default;
$navy--spacing: () !default;
$navy--shadows: () !default;
$navy--typography: () !default;
$navy--zIndex: () !default;


// Helper Namespace
$helper-ns: "h_";

$navy-content-width: 1280px;
