.rn-notification-panel__container {
  position: absolute;
}

.rn-notification-panel__button {
  background: transparent;
  border: none;
  cursor: pointer;
  display: block;
  line-height: 0;
  margin: 0 auto;
  padding: 5px;
  position: relative;
}

.rn-notification-panel__icon {
  height: 21px;
  width: 16px;
}

.rn-notification-panel__not-read {
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 4px;
  background: color(primary, 400);
  position: absolute;
  top: 14px;
  right: 12px;
  border: 0;

  &--notification-item {
    border: 1px solid color(neutral, 700);
    top: 0;
    right: 0;
  }
}

.rn-notification-panel .rn-iconfill {
  &:hover {
    fill: color(primary, 400);
  }
}

.rn-notification__transition-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
}

.rn-notification-leave {
  opacity: 0;
}

.rn-notification-enter {
  opacity: 0;
}

.rn-notification-enter.rn-notification-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.rn-notification-leave {
  opacity: 1;
}

.rn-notification-leave.rn-notification-leave-active {
  opacity: 0;
  transition: opacity 300ms;
}

@include breakpoint("s") {
  .rn-notification-panel__header {
    display: none;
  }
}

.rn-notifications {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.rn-notifications__view-all {
  font-size: font-size(xs);
  display: block;
  text-align: right;
  margin-right: spacing(3);
  margin-bottom: spacing(3);
  color: color(primary, 300);

  svg {
    position: relative;
    top: 4px;
  }

  a {
    text-decoration: none;
  }

  &:hover {
    svg {
      transform: translate3d(4px, 0, 0);
      transition: 0.2s;
    }
  }
}

.rn-notifications-item-wrapper {
  a,
  a:hover {
    color: color(neutral, 300);
    text-decoration: none;
  }

  &:hover {
    .rn-avatar {
      background: color(primary, 500);
    }

    .rn-notifications-item__content-strong {
      color: color(primary, 500);
    }
  }
}

.rn-notifications-item {
  display: flex;
  border-bottom: 1px solid color(neutral, 600);
  margin: spacing(3);
  padding-bottom: spacing(3);
}

.rn-notifications-item__avatar {
  position: relative;
}

.rn-notifications-item__content {
  margin-left: spacing(3);
  font-size: font-size(xs);
  line-height: 1.3;
}

.rn-notifications-item__content-strong {
  font-weight: bold;
  color: color(neutral, 000);
}

.rn-notifications-item__content-circle {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background: color(neutral, 300);
  margin: 0 4px 2px 4px;
}

.rn-notifications-item__content-description {
  color: color(neutral, 300);
  margin-top: spacing(1);
}
